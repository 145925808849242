<template>
  <b-tab :title="$t('HeaderCase.ReviewersDistributionList.tabTitle')">
    <b-card no-body>
      <template v-slot:header>
        <b-row>
          <b-col class="h3">
            {{ $t("HeaderCase.ReviewersDistributionList.title") }}
          </b-col>
        </b-row>
      </template>
      <b-list-group flush>
        <b-list-group-item
          v-for="item in caseHeader.reviewerDistributionList"
          v-bind:key="item.username"
        >
          <b-row>
            <b-col>
              {{ item.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </b-tab>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    busy: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("Cases", { caseHeader: "caseHeader" }),
  },
};
</script>
