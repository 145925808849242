<template>
  <b-card class="h-100" no-body>
    <b-overlay :show="show" spinner-variant="primary" no-wrap> </b-overlay>

    <b-skeleton-wrapper :loading="busy">
      <template #loading>
        <b-card-header header-border-variant="light">
          <b-skeleton></b-skeleton>
          <b-row>
            <b-col>
              <b-skeleton></b-skeleton>
            </b-col>
            <b-col>
              <b-skeleton></b-skeleton>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-skeleton></b-skeleton>
            </b-col>
            <b-col>
              <b-skeleton></b-skeleton>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-skeleton></b-skeleton>
            </b-col>
            <b-col>
              <b-skeleton></b-skeleton>
            </b-col>
          </b-row>
        </b-card-header>
      </template>
      <b-card-header header-border-variant="light">
        <b-form class="mb-3">
          <b-form-row>
            <b-col cols="12">
              <b-container flow>
                <b-row>
                  <b-col cols="6" md="6">
                    <h2>{{ caseHeader.name }}</h2>
                  </b-col>
                  <b-col cols="6" md="6">
                    <b-button-toolbar
                      key-nav
                      :aria-label="$t('HeaderCase.HeaderToolbar')"
                    >
                      <b-button-group class="mx-1 ml-auto">
                        <b-button
                          v-if="$route.hash === '#edit'"
                          variant="primary"
                          v-b-tooltip.hover
                          size="sm"
                          :title="$t('HeaderCase.DisplayText')"
                          @click="onDisplay()"
                        >
                          <view-icon />
                        </b-button>
                        <b-button
                          v-if="$route.hash === '#display'"
                          variant="primary"
                          v-b-tooltip.hover
                          size="sm"
                          :title="$t('HeaderCase.EditText')"
                          @click="onEdit()"
                        >
                          <b-icon-pencil-fill />
                        </b-button>
                        <div v-if="caseHeader.owner === Name">
                          <b-button
                            variant="primary"
                            size="sm"
                            v-b-tooltip.hover
                            :title="$t('HeaderCase.UserCollaboration')"
                            :to="{
                              name: 'UserCollaboration',
                              params: { idCase: $route.params.idCase },
                            }"
                          >
                            <b-icon-people />
                          </b-button>
                        </div>

                        <b-button
                          variant="primary"
                          v-b-tooltip.hover
                          size="sm"
                          :title="$t('views.subCases.buttons.print')"
                          @click="openPrint"
                        >
                          <b-icon-printer />
                        </b-button>
                        <export-option :idCalculation="$route.params.idCase" />
                      </b-button-group>
                      <b-button-group
                        class="mx-1"
                        v-if="caseHeader.owner === Name"
                      >
                        <ClosingCase :disabled="caseHeader.idStatus != 5" />
                        <SubmittingCase
                          :disabled="
                            caseHeader.idStatus != 1 &&
                            caseHeader.idStatus != 3 &&
                            caseHeader.idStatus != 6
                          "
                        />
                      </b-button-group>
                      <b-button-group
                        class="mx-1"
                        v-if="userRoles.isReviewer || userRoles.isAdminCustomer"
                      >
                        <AcceptingCase :disabled="caseHeader.idStatus != 4" />
                        <ReturningCase :disabled="caseHeader.idStatus != 4" />
                      </b-button-group>
                      <b-button-group v-if="$route.hash === '#edit'">
                        <ChangeName />
                        <ChangeOwner />
                      </b-button-group> </b-button-toolbar
                  ></b-col>
                </b-row>
              </b-container>
            </b-col>
            <b-col cols="12" md="6">
              <b-container flow>
                <b-row>
                  <b-col cols="5"
                    ><strong>{{
                      $t("HeaderCase.OrganizationalUnit")
                    }}</strong></b-col
                  >
                  <b-col cols="7">{{
                    caseHeader.organizationalUnitName
                  }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="5"
                    ><strong>{{ $t("HeaderCase.Owner") }}</strong></b-col
                  >
                  <b-col cols="7">{{ caseHeader.owner }}</b-col>
                </b-row>
              </b-container>
            </b-col>
            <b-col cols="12" md="6">
              <b-container flow>
                <b-row>
                  <b-col cols="4"
                    ><strong> {{ $t("HeaderCase.CreatedOn") }}</strong></b-col
                  >
                  <b-col cols="8">{{ FormatDate(caseHeader.createdOn) }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="4"
                    ><strong>{{ $t("HeaderCase.LastModified") }}</strong></b-col
                  >
                  <b-col cols="8">{{
                    FormatDate(caseHeader.lastModified)
                  }}</b-col>
                </b-row>
                <b-row v-if="caseHeader.closedOn">
                  <b-col cols="4"
                    ><strong>{{ $t("HeaderCase.ClosedOn") }}</strong></b-col
                  >
                  <b-col cols="8">{{ FormatDate(caseHeader.closedOn) }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="4"
                    ><strong>{{ $t("HeaderCase.Status") }}</strong></b-col
                  >
                  <b-col cols="8">{{
                    caseHeader.idStatus
                      ? $t("status." + caseHeader.idStatus)
                      : ""
                  }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="4"
                    ><strong>{{ $t("HeaderCase.CreatedBy") }}</strong></b-col
                  >
                  <b-col cols="8">{{ caseHeader.createdBy }}</b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-form-row>
        </b-form>
      </b-card-header>
    </b-skeleton-wrapper>
    <div class="h-100 overflow-auto">
      <Edit
        ref="caseHeaderEdit"
        :busy="busy"
        v-if="$route.hash === '#edit'"
        @changed="change = true"
      />
      <Display :busy="busy" v-if="$route.hash === '#display'" />
    </div>
    <b-card-footer class="text-right" v-if="$route.hash === '#edit'">
      <b-btn
        size="sm"
        variant="success"
        @click="onSaveClick()"
        :disabled="!change"
        >{{ $t("general.save") }}</b-btn
      >
    </b-card-footer>
    <b-modal
      v-model="loading"
      :title="
        $route.hash === '#edit'
          ? $t('HeaderCase.VisualizationModeTitle')
          : $t('HeaderCase.EditModeTitle')
      "
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      hide-footer
    >
      <div class="d-block text-center">
        <h5>
          {{
            $route.hash === "#edit"
              ? $t("HeaderCase.VisualizationMode")
              : $t("HeaderCase.EditMode")
          }}
        </h5>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import Display from "@/components/HeaderCase/Display.vue";
import Edit from "@/components/HeaderCase/Edit.vue";
import ChangeName from "@/components/HeaderCase/ChangeName.vue";
import ChangeOwner from "@/components/HeaderCase/ChangeOwner.vue";
import ClosingCase from "@/components/HeaderCase/ClosingCase.vue";
import AcceptingCase from "@/components/HeaderCase/AcceptingCase.vue";
import SubmittingCase from "@/components/HeaderCase/SubmittingCase.vue";
import ReturningCase from "@/components/HeaderCase/ReturningCase.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import ViewIcon from "@/components/general/icons/View.vue";
import ExportOption from "@/components/cost-calculation/export-option.vue";

const components = {
  Display,
  Edit,
  ChangeName,
  ChangeOwner,
  ClosingCase,
  AcceptingCase,
  SubmittingCase,
  ReturningCase,
  "view-icon": ViewIcon,
  ExportOption,
};

const data = () => {
  return {
    busy: true,
    show: false,
    change: false,
    loading: false,
  };
};

const computed = {
  ...mapGetters("Cases", { caseHeader: "caseHeader" }),
  ...mapGetters("Conditions", ["conditions"]),
  ...mapGetters({
    Name: "GET_NAME",
    userRoles: "GET_USERROLES",
    isAdmin: "GET_ISADMIN",
  }),
};

const methods = {
  ...mapActions("Cases", [
    "getCaseHeader",
    "getCaseHeaderStatus",
    "putOpenStatus",
    "putEditStatus",
  ]),
  ...mapActions("Conditions", ["getConditions"]),
  onEdit() {
    if (this.caseHeader.status == "Submitted") {
      this.loading = true;
      this.getCaseHeader({
        api: this.$api,
        session: this.$session,
        idCase: this.caseHeader.idCase,
      }).finally(() => {
        this.loading = false;
        this.$router.push({ hash: "#edit" });
      });
    } else {
      this.loading = true;
      this.busy = false;
      this.putEditStatus({
        idCostCalculation: this.caseHeader.idCase,
      })
        .then(() => {
          this.getCaseHeader({
            api: this.$api,
            session: this.$session,
            idCase: this.caseHeader.idCase,
          }).finally(() => {
            this.loading = false;
            this.$router.push({ hash: "#edit" });
            this.busy = false;
          });
        })
        .catch((error) => {
          this.$bvToast.toast(error, {
            title: this.$t("HeaderCase.EditText"),
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.loading = false;
          this.busy = false;
        });
    }
  },
  onDisplay() {
    if (this.caseHeader.status == "Submitted") {
      this.$bvModal
        .msgBoxConfirm(this.$t("HeaderCase.UnsavedChangesMessages"), {
          title: this.$t("HeaderCase.UnsavedChangesTitle"),
          centered: true,
        })
        .then((answer) => {
          if (answer) {
            this.loading = true;
            this.getCaseHeader({
              api: this.$api,
              session: this.$session,
              idCase: this.caseHeader.idCase,
            }).finally(() => {
              this.loading = false;
              this.$router.push({ hash: "#display" });
              this.busy = false;
            });
          } else {
            this.busy = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.$bvModal
        .msgBoxConfirm(this.$t("HeaderCase.UnsavedChangesMessages"), {
          title: this.$t("HeaderCase.UnsavedChangesTitle"),
          centered: true,
        })
        .then((answer) => {
          if (answer) {
            this.loading = true;
            this.putOpenStatus({
              idCostCalculation: this.caseHeader.idCase,
            })
              .then(() => {
                this.getCaseHeader({
                  api: this.$api,
                  session: this.$session,
                  idCase: this.caseHeader.idCase,
                }).finally(() => {
                  this.loading = false;
                  this.$router.push({ hash: "#display" });
                });
              })
              .finally(() => {
                this.busy = false;
              });
          } else {
            this.busy = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  onSaveClick() {
    this.show = true;
    this.$refs["caseHeaderEdit"]
      .saveCaseHeadeFile()
      .then(() => {
        this.change = false;
        this.$router.push({ hash: "#display" });
      })
      .catch(() => {
        this.$forceUpdate();
      })
      .finally(() => {
        this.show = false;
      });
  },
  FormatDate(date) {
    if (date) {
      return moment
        .utc(String(date))
        .local()
        .format(this.$t("caselist.dateformat"));
    }
    return "";
  },
  async openPrint() {
    await this.$api
      .axios({
        url:
          "/cases/export/" +
          this.$route.params.idCase +
          "?format=PDFWithoutComments",
        method: "GET",
        responseType: "blob",
      })
      .then((response) => {
        let blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        let w = window.open(url);
        w.print();
      });
  },
  ShowExportModal() {
    this.$bvModal.show("export-modal");
  },
};
const lifeCycle = {
  created() {
    const { idCase } = this.$route.params;
    if (!idCase) return;
    this.busy = true;
    this.getCaseHeader({
      api: this.$api,
      session: this.$session,
      idCase,
    }).finally(() => {
      this.busy = false;
      this.change = true;
    });
    this.getConditions({
      api: this.$api,
      session: this.$session,
      enabled: true,
    });
  },
};
export default {
  watch: {
    "caseHeader.duration"() {
      this.change = true;
    },
  },
  components,
  data,
  computed,
  methods,
  ...lifeCycle,
};
</script>

<style></style>
