<template>
  <b-tab :title="$t('HeaderCase.ReviewersDistributionList.tabTitle')">
    <b-card no-body>
      <template v-slot:header>
        <b-row>
          <b-col class="h3">
            {{ $t("HeaderCase.ReviewersDistributionList.title") }}
          </b-col>
          <b-col class="text-right">
            <reviewers-add-button :idCostCalculation="caseHeader.idCase" />
          </b-col>
        </b-row>
      </template>
      <b-list-group flush>
        <b-list-group-item
          v-for="item in caseHeader.reviewerDistributionList"
          v-bind:key="item.username"
        >
          <b-row>
            <b-col>
              {{ item.name }}
            </b-col>
            <b-col class="text-right">
              <b-btn
                variant="danger"
                size="sm"
                v-b-modal="idModal + item.username"
                v-b-tooltip.hover
                :title="
                  $t('HeaderCase.ReviewersDistributionList.deleteReviewer')
                "
                id="update-help-entry-button"
              >
                <b-icon-trash />
                <confirm
                  :id="idModal + item.username"
                  :title="
                    $t('HeaderCase.ReviewersDistributionList.deleteReviewer')
                  "
                  :message="
                    $t(
                      'HeaderCase.ReviewersDistributionList.reviewerConfirmation'
                    )
                  "
                  @onOk="deleteReviewer(item)"
                  variantHeader="danger"
                  variantText="light"
                />
              </b-btn>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </b-tab>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ReviewersAddButton from "@/components/cost-calculation/document/reviewers-email-add.vue";
import Confirm from "@/components/Confirm.vue";

export default {
  components: {
    ReviewersAddButton,
    Confirm,
  },
  props: {
    busy: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      idModal: "delete-reviewers-modal",
    };
  },
  computed: {
    ...mapGetters("Cases", ["caseHeader"]),
  },
  methods: {
    ...mapActions("Cases", ["getCaseHeader", "deleteReviewerDistributionList"]),
    deleteReviewer(item) {
      this.deleteReviewerDistributionList({
        idCostCalculation: this.caseHeader.idCase,
        reviewer: item.username,
      })
        .then((response) => {
          this.$bvToast.toast(response.text, {
            variant: "success",
            title: this.$t(
              "HeaderCase.ReviewersDistributionList.deleteReviewer"
            ),
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((errorMsg) => {
          this.$bvToast.toast(errorMsg, {
            variant: "danger",
            title: this.$t(
              "HeaderCase.ReviewersDistributionList.deleteReviewer"
            ),
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.$bvModal.hide(this.idModal + item);
        });
    },
  },
};
</script>
