<template>
  <div>
    <b-tabs card justified>
      <fields-edit-tab
        ref="fields-edit-tab"
        @click="tab = 'BasicInformation'"
        :busy="busy"
        :caseFields="caseHeader.caseFields || []"
        @changed="$emit('changed')"
      />
      <b-tab :title="$t('general.internal')" lazy @click="tab = 'Internal'">
        <div v-if="caseHeader.file">
          <b-table
            v-for="(summationGroups, index) in caseHeader.file.summationGroups"
            :key="summationGroups.idSummation"
            striped
            hover
            small
            fixed
            stacked="md"
            thead-class="bg-primary text-light text-right"
            tfoot-class="bg-primary text-light text-right"
            tbody-tr-class="text-right"
            :show-empty="summationGroups.summations.length <= 0"
            :foot-clone="summationGroups.summations.length > 0"
            :fields="summationGroups.showBudget ? fields : filterByBudget"
            :items="mapItems(summationGroups)"
            :busy="busy"
            caption-top
          >
            <template #table-caption>{{
              summationGroups.summationName
            }}</template>
            <template #table-busy>
              <b-skeleton-table
                show-footer
                :rows="5"
                :table-props="{ bordered: true, striped: true, small: true }"
              ></b-skeleton-table>
            </template>
            <!-- rows -->
            <template #head(subcaseName)="data">
              <div class="text-left">{{ data.label }}</div>
            </template>
            <template #cell(subcaseName)="data">
              <div class="text-left">{{ data.value }}</div>
            </template>
            <template #cell(summationName)>
              {{ summationGroups.summationName }}
            </template>
            <template #cell(internalCost)="data">
              <i18n-n :value="data.value" format="currency" />
            </template>
            <template #cell(externalCost)="data">
              <i18n-n :value="data.value" format="currency" />
            </template>
            <template #cell(budget)="data">
              <currency-input
                class="form-control text-right"
                v-model="data.value"
                currency="EUR"
                :locale="$i18n.locale"
                v-if="!userRoles.isReviewer"
                @change="onUpdateBudget($event, data.item)"
              />

              <i18n-n v-else :value="data.value" format="currency" />
            </template>
            <template #cell(internalDifference)="data">
              <i18n-n
                :value="data.item.budget - data.item.internalCost"
                format="currency"
              />
            </template>
            <template #cell(externalDifference)="data">
              <i18n-n
                :value="data.item.budget - data.item.externalCost"
                format="currency"
              />
            </template>
            <!-- footer -->
            <template #foot()> <div class="text-primary">.</div> </template>
            <template #foot(subcaseName)>
              <div class="text-left">{{ $t("HeaderCase.Edit.Totals") }}</div>
            </template>
            <template #foot(internalCost)>
              <i18n-n
                :value="
                  calculateTotals(
                    'internalCost',
                    caseHeader.file.summationGroups[index].summations
                  )
                "
                format="currency"
              />
            </template>
            <template #foot(externalCost)>
              <i18n-n
                :value="
                  calculateTotals(
                    'externalCost',
                    caseHeader.file.summationGroups[index].summations
                  )
                "
                format="currency"
              />
            </template>
            <template #foot(budget)>
              <i18n-n
                :value="
                  calculateTotals(
                    'budget',
                    caseHeader.file.summationGroups[index].summations
                  )
                "
                format="currency"
              />
            </template>
            <template #foot(internalDifference)>
              <i18n-n
                :value="
                  calculateTotals(
                    'internalDifference',
                    caseHeader.file.summationGroups[index].summations
                  )
                "
                format="currency"
              />
            </template>
            <template #foot(externalDifference) variant="danger">
              <i18n-n
                :value="
                  calculateTotals(
                    'externalDifference',
                    caseHeader.file.summationGroups[index].summations
                  )
                "
                format="currency"
              />
            </template>
          </b-table></div
      ></b-tab>
      <b-tab :title="$t('HeaderCase.Edit.External')" @click="tab = 'External'">
        <div v-if="caseHeader.file">
          <b-table
            v-for="(summationGroups, index) in caseHeader.file.summationGroups"
            :key="summationGroups.idSummation"
            striped
            hover
            small
            fixed
            stacked="md"
            thead-class="bg-primary text-light text-right"
            tfoot-class="bg-primary text-light text-right"
            tbody-tr-class="text-right"
            :show-empty="summationGroups.summations.length <= 0"
            :foot-clone="summationGroups.summations.length > 0"
            :fields="summationGroups.showBudget ? fields : filterByBudget"
            :items="mapItems(summationGroups)"
            :busy="busy"
            caption-top
          >
            <template #table-caption>{{
              summationGroups.summationName
            }}</template>
            <template #table-busy>
              <b-skeleton-table
                show-footer
                :rows="5"
                :table-props="{ bordered: true, striped: true, small: true }"
              ></b-skeleton-table>
            </template>
            <!-- rows -->
            <template #head(subcaseName)="data">
              <div class="text-left">{{ data.label }}</div>
            </template>
            <template #cell(subcaseName)="data">
              <div class="text-left">{{ data.value }}</div>
            </template>
            <template #cell(summationName)>
              {{ summationGroups.summationName }}
            </template>
            <template #cell(internalCost)="data">
              <i18n-n :value="data.value" format="currency" />
            </template>
            <template #cell(externalCost)="data">
              <i18n-n :value="data.value" format="currency" />
            </template>
            <template #cell(budget)="data">
              <currency-input
                class="form-control text-right"
                v-model="data.value"
                currency="EUR"
                :locale="$i18n.locale"
                v-if="!userRoles.isReviewer"
                @change="onUpdateBudget($event, data.item)"
              />

              <i18n-n v-else :value="data.value" format="currency" />
            </template>
            <template #cell(internalDifference)="data">
              <i18n-n
                :value="data.item.budget - data.item.internalCost"
                format="currency"
              />
            </template>
            <template #cell(externalDifference)="data">
              <i18n-n
                :value="data.item.budget - data.item.externalCost"
                format="currency"
              />
            </template>
            <!-- footer -->
            <template #foot()> <div class="text-primary">.</div> </template>
            <template #foot(subcaseName)>
              <div class="text-left">{{ $t("HeaderCase.Edit.Totals") }}</div>
            </template>
            <template #foot(internalCost)>
              <i18n-n
                :value="
                  calculateTotals(
                    'internalCost',
                    caseHeader.file.summationGroups[index].summations
                  )
                "
                format="currency"
              />
            </template>
            <template #foot(externalCost)>
              <i18n-n
                :value="
                  calculateTotals(
                    'externalCost',
                    caseHeader.file.summationGroups[index].summations
                  )
                "
                format="currency"
              />
            </template>
            <template #foot(budget)>
              <i18n-n
                :value="
                  calculateTotals(
                    'budget',
                    caseHeader.file.summationGroups[index].summations
                  )
                "
                format="currency"
              />
            </template>
            <template #foot(internalDifference)>
              <i18n-n
                :value="
                  calculateTotals(
                    'internalDifference',
                    caseHeader.file.summationGroups[index].summations
                  )
                "
                format="currency"
              />
            </template>
            <template #foot(externalDifference) variant="danger">
              <i18n-n
                :value="
                  calculateTotals(
                    'externalDifference',
                    caseHeader.file.summationGroups[index].summations
                  )
                "
                format="currency"
              />
            </template>
          </b-table>
        </div>
      </b-tab>
      <resource-pool-view-tab
        showTotals
        :resources="caseHeader.resources || []"
        :duration="caseHeader.duration"
      />
      <ParametersPanel :editMode="true" @changedValue="$emit('changed')" />
      <ReviewersEmail :busy="busy" />
    </b-tabs>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import { InternalFields, ExternalFields } from "./helper";
import ParametersPanel from "@/components/HeaderCase/ParametersPanelView.vue";
import ResourcePoolViewTab from "@/components/cost-calculation/resource-pool-view-tab.vue";
import FieldsEditTab from "../cost-calculation/document/fields-edit-tab.vue";
import ReviewersEmail from "@/components/cost-calculation/document/reviewers-email-edit.vue";

const props = {
  busy: {
    type: Boolean,
    default: false,
  },
};

const data = () => {
  return {
    show: false,
    changed: false,
    tab: "Internal",
  };
};

export default {
  props,
  data,
  methods: {
    ...mapActions("Cases", {
      putCaseHeader: "putCaseHeader",
      putOpenStatus: "putOpenStatus",
      putParameters: "putParameters",
    }),
    mapItems(summationGroups) {
      let items = summationGroups.summations;
      items.forEach(
        (s) =>
          (s._cellVariants = {
            internalDifference: s.internalDifference < 0 ? "danger" : "success",
            externalDifference: s.externalDifference < 0 ? "danger" : "success",
          })
      );
      return summationGroups.showSubcase ? items : [];
    },
    onClickTab(tab) {
      this.tab = tab;
    },
    validateMandatoryFields() {
      var check = true;
      for (var index in this.caseHeader.caseFields) {
        var currentField = this.caseHeader.caseFields[index];
        if (
          currentField.mandatory &&
          !currentField.reviewerRequired &&
          (!currentField.fieldValue || currentField.fieldValue == "")
        ) {
          Vue.set(currentField, "status", false);
          check = false;
        } else {
          Vue.set(currentField, "status", true);
        }
      }
      return check;
    },
    saveCaseHeadeFile() {
      if (!this.validateMandatoryFields())
        return new Promise((resolve, reject) => {
          reject();
        });
      const file = {
        summationGroups: this.caseHeader.file.summationGroups,
        parameters: this.caseHeader.file.parameters,
      };
      return new Promise((resolve, reject) => {
        this.putCaseHeader({
          api: this.$api,
          session: this.$session,
          idOU: this.caseHeader.idOrganizationalUnit,
          idCase: this.caseHeader.idCase,
          duration: this.caseHeader.duration,
          idStudyType: this.caseHeader.idStudyType,
          file,
          resources: this.caseHeader.resources,
          caseFields: this.caseHeader.caseFields,
        })
          .then(() => {
            this.putParameters({
              api: this.$api,
              idCase: this.caseHeader.idCase,
              parameters: this.caseHeader.file.parameters,
            }).then(() => {
              if (!this.userRoles.isReviewer) {
                this.putOpenStatus({
                  idCostCalculation: this.caseHeader.idCase,
                }).then(() => {
                  resolve();
                });
              } else {
                resolve();
              }
            });
          })
          .catch(() => {
            reject();
          });
      });
    },
    onUpdateBudget(oEvent, item) {
      item.budget = oEvent;
      item.internalDifference = item.budget - item.internalCost;
      item.externalDifference = item.budget - item.externalCost;
      item._cellVariants.internalDifference =
        item.internalDifference < 0 ? "danger" : "success";
      item._cellVariants.externalDifference =
        item.externalDifference < 0 ? "danger" : "success";
      this.$emit("changed");
    },
    calculateTotals(sField, summations) {
      return summations.reduce((pre, curr) => {
        return +pre + +curr[sField];
      }, 0);
    },
  },
  computed: {
    ...mapGetters("Cases", { caseHeader: "caseHeader" }),
    ...mapGetters({
      userRoles: "GET_USERROLES",
    }),
    fields() {
      return this[`${this.tab}Fields`];
    },
    filterByBudget() {
      let fields = [];
      this[`${this.tab}Fields`].forEach((element, index) => {
        if (index < 2) {
          fields.push(element);
        }
      });
      return fields;
    },
    InternalFields,
    ExternalFields,
  },
  components: {
    ResourcePoolViewTab,
    ParametersPanel,
    FieldsEditTab,
    ReviewersEmail,
  },
};
</script>

<style></style>
