<template>
  <b-tab
    :title="$t('views.caseTemplateEdition.basisInformation')"
    active
    @click="$emit('click')"
  >
    <b-table
      :fields="fields"
      :items="items"
      outlined
      small
      fixed
      :busy="busy"
      show-empty
      :emptyText="$t('placeholders.NoInformationAdded')"
    >
      <template #table-busy>
        <b-skeleton-table
          show-footer
          :rows="5"
          :columns="2"
          :table-props="{ bordered: true, striped: true, small: true }"
        ></b-skeleton-table>
      </template>
      <template v-slot:cell(fieldValue)="row">
        <b-form-input
          v-if="row.item.idCaseField == -2"
          :placeholder="$t('views.cases.durationInMonths')"
          class="form-control text-right"
          v-model="row.item.fieldValue"
          required
          type="number"
          :number="true"
          trim
          @change="verifyNegativeValues"
          min="0"
        />
        <b-form-select
          v-if="row.item.idCaseField == -1"
          v-model="row.item.fieldValue"
          :options="conditions"
          text-field="conditionName"
          value-field="idCondition"
          @change="setStudyType"
        >
          <template #first>
            <b-form-select-option disabled :value="null">{{
              $t("views.caseTemplateEdition.fields.select-study")
            }}</b-form-select-option>
          </template>
        </b-form-select>
        <b-form-input
          v-if="row.item.idCaseField > 0"
          :placeholder="row.item.placeholder"
          v-model="row.item.fieldValue"
          max="150"
          :state="row.item.status"
          @change="$emit('changed')"
        />
        <b-form-invalid-feedback id="input-live-feedback">
          {{ $t("general.dataRequired") }}
        </b-form-invalid-feedback>
      </template>
    </b-table>
  </b-tab>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
export default {
  props: {
    busy: {
      type: Boolean,
      required: true,
    },
    caseFields: {
      type: Array,
      required: true,
    },
  },
  watch: {
    caseFields() {
      for (var index in this.caseFields) {
        var currentField = this.caseFields[index];
        if (!("status" in currentField)) Vue.set(currentField, "status", null);
      }
    },
  },
  computed: {
    ...mapGetters("Cases", { caseHeader: "caseHeader" }),
    ...mapGetters("Conditions", ["conditions"]),
    items() {
      const tableItems = [
        {
          fieldTitle: "Duration",
          fieldValue: this.caseHeader.duration,
          idCaseField: -2,
        },
        {
          fieldTitle: "Study Type",
          fieldValue: this.caseHeader.idStudyType,
          idCaseField: -1,
        },
      ];
      if (Array.isArray(this.caseFields)) {
        return [...tableItems, ...this.caseFields];
      } else {
        return tableItems;
      }
    },
    fields() {
      return [
        {
          key: "fieldTitle",
          sortable: false,
          label: "Name",
        },
        {
          key: "fieldValue",
          sortable: false,
          label: this.$t("fields.ParameterPanel.Value"),
        },
      ];
    },
  },
  methods: {
    ...mapActions("Conditions", ["getConditions"]),
    verifyNegativeValues(event) {
      event = parseInt(event);
      if (event < 0) {
        event = 0;
      }
      this.caseHeader.duration = event;
    },
    setStudyType(event) {
      this.caseHeader.idStudyType = event;
    },
  },
  mounted() {
    this.getConditions({
      session: this.$session,
      api: this.$api,
      enabled: true,
    }).catch((e) => {
      console.log(e);
    });
  },
};
</script>

<style lang="scss" scoped>
.rightBlock {
  float: right;
}
.leftBlock {
  float: left;
}
</style>
