<template>
  <div>
    <b-button
      v-b-tooltip.hover
      :title="$t('HeaderCase.ChangeOwner.title')"
      size="sm"
      :disabled="disabled"
      variant="primary"
      v-b-modal.modal-change-ownwer
    >
      <b-icon-person-lines-fill />

      <b-modal
        id="modal-change-ownwer"
        ref="modal"
        :title="$t('HeaderCase.ChangeOwner.title')"
        centered
        header-bg-variant="primary"
        header-text-variant="light"
        :cancel-disabled="busy"
        :ok-disabled="busy"
        @show="onShow"
        @hidden="resetModal"
        @ok="handleOk"
      >
        <b-overlay
          :show="busy"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group
              :label="$t('HeaderCase.ChangeOwner.newOwner')"
              label-for="name-input"
              :invalid-feedback="$t('HeaderCase.ChangeOwner.NameRequired')"
              label-cols="3"
              :state="form.nameState"
            >
              <b-form-select
                id="name-select"
                v-model="form.userName"
                :options="userList"
                text-field="name"
                value-field="userName"
                :state="form.nameState"
                :disabled="busy"
                required
              >
                <template #first>
                  <b-form-select-option :value="null" disabled>{{
                    $t("HeaderCase.ChangeOwner.PleaseSelectOption")
                  }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </form></b-overlay
        >
        <template v-slot:modal-footer>
          <slot name="footer">
            <b-button
              @click="$bvModal.hide('modal-change-ownwer')"
              :disabled="busy"
              >{{ $t("general.cancel") }}</b-button
            ><b-button variant="primary" @click="handleOk" :disabled="busy">{{
              $t("general.ok")
            }}</b-button></slot
          >
        </template>
      </b-modal>
    </b-button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      busy: false,
      form: {
        userName: null,
        nameState: null,
      },
    };
  },
  computed: {
    ...mapGetters("Cases", { caseHeader: "caseHeader", userList: "userList" }),
  },
  methods: {
    ...mapActions("Cases", ["fetchUserList", "changeUser"]),
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.form.nameState = valid;
      return valid;
    },
    resetModal() {
      this.form.name = this.caseHeader.name;
      this.form.nameState = null;
      this.busy = false;
    },
    async onShow() {
      this.busy = true;
      await this.fetchUserList({
        session: this.$session,
        api: this.$api,
        idOU: this.caseHeader.idOrganizationalUnit,
      });
      this.busy = false;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    async handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      try {
        this.busy = true;
        let newOwner = this.userList.find(
          (x) => x.userName == this.form.userName
        );
        await this.changeUser({
          session: this.$session,
          api: this.$api,
          caseInfo: this.caseHeader,
          newOwner: this.form.userName,
          nameOwner: newOwner.name,
        });
      } catch (error) {
        console.log(console.error());
      } finally {
        this.$nextTick(() => {
          this.$bvModal.hide("modal-change-ownwer");
        });
        this.busy = false;
      }
    },
  },
};
</script>

<style></style>
