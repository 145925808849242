<template>
  <div>
    <b-overlay :show="show" no-wrap fixed> </b-overlay>
    <b-button
      v-b-tooltip.hover
      :title="$t('views.subCases.buttons.closeCase')"
      size="sm"
      variant="primary"
      :disabled="disabled"
      @click="showMsgBoxOne"
    >
      <b-icon-box-arrow-down
    /></b-button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapGetters("Cases", { caseHeader: "caseHeader" }),
  },
  methods: {
    ...mapActions("Cases", ["putClosedStatus"]),
    showMsgBoxOne() {
      this.boxOne = "";
      this.$bvModal
        .msgBoxConfirm(this.$t("HeaderCase.ClosingCase.confirmation"), {
          title: this.$t("HeaderCase.ClosingCase.title"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          cancelTitle: this.$t("general.cancel"),
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.show = true;
            this.putClosedStatus({
              idCases: this.caseHeader.idCase,
            })
              .then(() => {
                this.$router.push({ hash: "#display" });
                this.$bvToast.toast(this.message, {
                  title: this.$t(
                    "HeaderCase.AcceptCase.acceptingCaseSuccessful"
                  ),
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                this.show = false;
              })
              .catch((error) => {
                this.$bvToast.toast(error, {
                  title: this.$t("general.error"),
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                this.show = false;
                this.$router.push({ hash: "#display" });
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style></style>
