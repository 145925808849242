export function InternalFields() {
  return [
    {
      key: "subcaseName",
      label: this.$t("HeaderCase.Fields.Subcase"),
      tdClass: "text-left",
    },
    /*     {
      key: "summationName",
      label: this.$t("HeaderCase.Fields.Summation"),
      tdClass: "text-left",
    }, */
    { key: "internalCost", label: this.$t("HeaderCase.Fields.Cost") },
    { key: "budget", label: this.$t("HeaderCase.Fields.Budget") },
    {
      key: "internalDifference",
      label: this.$t("HeaderCase.Fields.Difference"),
    },
  ];
}
export function ExternalFields() {
  return [
    {
      key: "subcaseName",
      label: this.$t("HeaderCase.Fields.Subcase"),
      tdClass: "text-left",
    },
    /*    {
      key: "summationName",
      label: this.$t("HeaderCase.Fields.Summation"),
      tdClass: "text-left",
    }, */
    { key: "externalCost", label: this.$t("HeaderCase.Fields.Cost") },
    { key: "budget", label: this.$t("HeaderCase.Fields.Budget") },
    {
      key: "externalDifference",
      label: this.$t("HeaderCase.Fields.Difference"),
    },
  ];
}

export const CaseStatus = {
  /// When the case is open and can be edited
  Open: 1,
  /// When the case is closed
  Closed: 2,
  /// When the case is being edited
  Edition: 3,
  // When the case is submitted to reviewer
  Submitted: 4,
  // When the case was accepted by reviewer
  Accepted: 5,
  // When the case was returned by reviewer
  Returned: 6,
};
