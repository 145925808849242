<template>
  <div>
    <b-button
      v-b-tooltip.hover
      :title="$t('HeaderCase.ChangeName.title')"
      size="sm"
      :disabled="disabled"
      variant="primary"
      v-b-modal.modal-change-case-name
    >
      <b-icon-bootstrap-reboot
    /></b-button>

    <b-modal
      id="modal-change-case-name"
      ref="modal"
      :title="$t('HeaderCase.ChangeName.title')"
      :cancel-disabled="busy"
      :ok-disabled="busy"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <b-overlay
        :show="busy"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :label="$t('HeaderCase.ChangeName.form.Name')"
            label-for="name-input"
            :invalid-feedback="caseNameFeedback"
            :state="form.nameState"
          >
            <b-form-input
              id="name-input"
              v-model="form.name"
              maxlength="100"
              :state="form.nameState"
              :disabled="busy"
              required
              :formatter="formatter"
            ></b-form-input>
          </b-form-group></form
      ></b-overlay>
      <template v-slot:modal-footer>
        <slot name="footer">
          <b-button
            @click="$bvModal.hide('modal-change-case-name')"
            :disabled="okLoading"
            >{{ $t("general.cancel") }}</b-button
          ><b-button
            variant="primary"
            @click="handleOk"
            :disabled="okLoading"
            >{{ $t("general.ok") }}</b-button
          ></slot
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      busy: false,
      form: {
        name: "",
        nameState: null,
      },
      caseNameFeedback: this.$t("views.cases.nameRequired"),
      caseNameRegex: /\/|\*|\?|\[|\]|:|\|/g,
    };
  },
  computed: {
    ...mapGetters("Cases", { caseHeader: "caseHeader" }),
  },
  methods: {
    ...mapActions("Cases", { renameCaseHeader: "renameCaseHeader" }),
    formatter(value) {
      if (value.match(this.caseNameRegex)) {
        this.caseNameFeedback = this.$t("views.cases.notAllowedSymbols");
        this.form.nameState = false;
      } else {
        this.form.nameState = null;
      }
      return value.replace(this.caseNameRegex, "");
    },
    checkFormValidity() {
      this.caseNameFeedback = this.$t("views.cases.nameRequired");
      const valid = this.$refs.form.checkValidity();
      this.form.nameState = valid;
      return valid;
    },
    resetModal() {
      this.form.name = this.caseHeader.name;
      this.form.nameState = null;
      this.busy = false;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      if (this.form.name === this.caseHeader.name) {
        this.$nextTick(() => {
          this.$bvModal.hide("modal-change-case-name");
        });
        return;
      }
      this.busy = true;
      this.renameCaseHeader({
        api: this.$api,
        session: this.$session,
        idOU: this.caseHeader.idOrganizationalUnit,
        idCase: this.caseHeader.idCase,
        newName: this.form.name,
      })
        .then(() => {
          this.$nextTick(() => {
            this.$bvModal.hide("modal-change-case-name");
          });
        })
        .finally(() => {
          this.busy = false;
        });
    },
  },
};
</script>

<style></style>
