<template>
  <b-tab :title="$t('fields.ResourcePool.ResourcePool')" lazy>
    <b-table
      :fields="fields"
      :items="resources"
      :busy="Loading"
      outlined
      small
      fixed
      show-empty
      :emptyText="$t('placeholders.NoInformationAdded')"
    >
      <template v-slot:table-busy>
        <simple-loader />
      </template>

      <template v-slot:cell(SalaryType)="row">
        {{ row.item.Salary.salaryType }}
      </template>

      <template v-slot:cell(Category)="row">
        {{ row.item.Category.categoryName }}
      </template>

      <template v-slot:cell(Years)="row">
        <div v-if="row.item.Salary.salaryType != 'Custom'">
          {{ row.item.Years ? row.item.Years.description : "" }}
        </div>
      </template>

      <template v-slot:cell(AnnualCost)="row">
        <div class="input text-left">
          <i18n-n :value="row.value" format="currency" />
        </div>
      </template>

      <template v-slot:cell(HourlyRate)="row">
        <div class="input text-left">
          <i18n-n :value="row.value" format="currency" />
        </div>
      </template>

      <template v-slot:cell(ExternalRate)="row">
        <div class="input text-left">
          <i18n-n :value="row.value" format="currency" />
        </div>
      </template>

      <template v-slot:cell(TotalResourceCost)="row">
        <div class="input text-left">
          <i18n-n :value="row.value" format="currency" />
        </div>
      </template>

      <template v-slot:cell(TotalResourceExternalCost)="row">
        <div class="input text-left">
          <i18n-n :value="row.value" format="currency" />
        </div>
      </template>

      <template v-slot:cell(TotalResourceHours)="row">
        <div class="input text-left">
          <i18n-n :value="row.value" />
        </div>
      </template>
    </b-table>
  </b-tab>
</template>

<script>
export default {
  props: {
    resources: {
      required: true,
      type: Array,
    },
    duration: {
      required: false,
      type: Number,
    },
    showTotals: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Loading: false,
    };
  },
  computed: {
    fields() {
      var fields = [
        {
          key: "Name",
          sortable: true,
          label: this.$t("fields.ResourcePool.Resource"),
        },
        {
          key: "SalaryType",
          sortable: true,
          label: this.$t("fields.ResourcePool.SalaryType"),
        },
        {
          key: "Category",
          sortable: true,
          label: this.$t("fields.ResourcePool.Category"),
        },
        {
          key: "Years",
          sortable: true,
          label: this.$t("fields.ResourcePool.Years"),
        },
        {
          key: "AnnualCost",
          sortable: false,
          label: this.$t("fields.ResourcePool.AnnualCost"),
        },
        {
          key: "HourlyRate",
          sortable: false,
          label: this.$t("fields.ResourcePool.HourlyRate"),
        },
        {
          key: "ExternalRate",
          sortable: false,
          label: this.$t("fields.ResourcePool.ExternalRate"),
        },
      ];
      if (this.showTotals) {
        fields.push(
          {
            key: "TotalResourceCost",
            label: this.$t("fields.ResourcePool.TotalResourceCost"),
          },
          {
            key: "TotalResourceExternalCost",
            label: this.$t("fields.ResourcePool.TotalResourceExternalCost"),
          },
          {
            key: "TotalResourceHours",
            label: this.$t("fields.ResourcePool.TotalResourceHours"),
          }
        );
      }
      return fields;
    },
  },
};
</script>

<style lang="scss" scoped>
.rightBlock {
  float: right;
}
.leftBlock {
  float: left;
}
</style>
