<template>
  <b-tab
    :title="$t('views.caseTemplateEdition.basisInformation')"
    active
    @click="$emit('click')"
  >
    <b-table
      :fields="fields"
      :items="items"
      outlined
      small
      fixed
      :busy="busy"
      show-empty
      :emptyText="$t('placeholders.NoInformationAdded')"
    >
      <template #table-busy>
        <b-skeleton-table
          :rows="5"
          :columns="2"
          :table-props="{ bordered: true, striped: true, small: true }"
        ></b-skeleton-table>
      </template>
      <template v-slot:cell(fieldValue)="row">
        <div v-if="row.item.status == false" class="text-danger">
          {{ $t("general.dataRequired") }}
        </div>
        <div v-else>
          {{ row.item.fieldValue }}
        </div>
      </template>
    </b-table>
  </b-tab>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    busy: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("Cases", { caseHeader: "caseHeader" }),
    items() {
      const tableItems = [
        {
          fieldTitle: "Duration",
          fieldValue: this.caseHeader.duration,
        },
        {
          fieldTitle: "Study Type",
          fieldValue: this.caseHeader.studyType,
        },
      ];
      if (Array.isArray(this.caseHeader.caseFields)) {
        return [...tableItems, ...this.caseHeader.caseFields];
      } else {
        return tableItems;
      }
    },
    fields() {
      return [
        {
          key: "fieldTitle",
          sortable: false,
          label: "Name",
        },
        {
          key: "fieldValue",
          sortable: false,
          label: this.$t("fields.ParameterPanel.Value"),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.rightBlock {
  float: right;
}
.leftBlock {
  float: left;
}
</style>
