<template>
  <div>
    <b-tabs card justified>
      <fields-view-tab @click="tab = 'BasicInformation'" :busy="busy" />
      <b-tab :title="$t('general.internal')" lazy @click="tab = 'Internal'">
        <div v-if="caseHeader.file">
          <b-table
            v-for="(summationGroups, index) in caseHeader.file.summationGroups"
            :key="summationGroups.idSummation"
            striped
            hover
            small
            fixed
            stacked="md"
            thead-class="bg-primary text-light text-right"
            tfoot-class="bg-primary text-light text-right"
            tbody-tr-class="text-right"
            :show-empty="summationGroups.summations.length <= 0"
            :foot-clone="summationGroups.summations.length > 0"
            :fields="summationGroups.showBudget ? fields : filterByBudget"
            :items="mapItems(summationGroups)"
            :busy="busy"
            caption-top
          >
            <template #table-caption>{{
              summationGroups.summationName
            }}</template>
            <template #table-busy>
              <b-skeleton-table
                show-footer
                :rows="5"
                :table-props="{ bordered: true, striped: true, small: true }"
              ></b-skeleton-table>
            </template>
            <template #head(subcaseName)="data">
              <div class="text-left">{{ data.label }}</div>
            </template>
            <template #cell(internalCost)="data">
              <i18n-n :value="data.value" format="currency" />
            </template>
            <template #cell(summationName)>
              {{ summationGroups.summationName }}
            </template>
            <template #cell(externalCost)="data">
              <i18n-n :value="data.value" format="currency" />
            </template>
            <template #cell(budget)="data">
              <i18n-n :value="data.value" format="currency" />
            </template>
            <template #cell(internalDifference)="data">
              <i18n-n :value="data.value" format="currency" />
            </template>
            <template #cell(externalDifference)="data">
              <i18n-n :value="data.value" format="currency" />
            </template>
            <template #foot()> <div class="text-primary">.</div> </template>
            <template #foot(subcaseName)>
              <div class="text-left">{{ $t("HeaderCase.Display.Totals") }}</div>
            </template>
            <template #foot(internalCost)>
              <div v-if="caseHeader.file">
                <i18n-n
                  :value="
                    caseHeader.file.summationGroups[index].internalGroupCost
                  "
                  format="currency"
                />
              </div>
            </template>
            <template #foot(externalCost)>
              <div v-if="caseHeader.file">
                <i18n-n
                  :value="
                    caseHeader.file.summationGroups[index].externalGroupCost
                  "
                  format="currency"
                />
              </div>
            </template>
            <template #foot(budget)>
              <div v-if="caseHeader.file">
                <i18n-n
                  :value="caseHeader.file.summationGroups[index].groupBudget"
                  format="currency"
                />
              </div>
            </template>
            <template #foot(internalDifference)>
              <div v-if="caseHeader.file">
                <i18n-n
                  :value="
                    caseHeader.file.summationGroups[index]
                      .internalGroupDifference
                  "
                  format="currency"
                />
              </div>
            </template>
            <template #foot(externalDifference)>
              <div v-if="caseHeader.file">
                <i18n-n
                  :value="
                    caseHeader.file.summationGroups[index]
                      .externalGroupDifference
                  "
                  format="currency"
                />
              </div>
            </template>
          </b-table></div
      ></b-tab>
      <b-tab
        :title="$t('HeaderCase.Display.External')"
        @click="tab = 'External'"
      >
        <div v-if="caseHeader.file">
          <b-table
            v-for="(summationGroups, index) in caseHeader.file.summationGroups"
            :key="summationGroups.idSummation"
            striped
            hover
            small
            fixed
            stacked="md"
            thead-class="bg-primary text-light text-right"
            tfoot-class="bg-primary text-light text-right"
            tbody-tr-class="text-right"
            :show-empty="summationGroups.summations.length <= 0"
            :foot-clone="summationGroups.summations.length > 0"
            :fields="summationGroups.showBudget ? fields : filterByBudget"
            :items="mapItems(summationGroups)"
            :busy="busy"
            caption-top
          >
            <template #table-caption>{{
              summationGroups.summationName
            }}</template>
            <template #table-busy>
              <b-skeleton-table
                show-footer
                :rows="5"
                :table-props="{ bordered: true, striped: true, small: true }"
              ></b-skeleton-table>
            </template>
            <template #head(subcaseName)="data">
              <div class="text-left">{{ data.label }}</div>
            </template>
            <template #cell(internalCost)="data">
              <i18n-n :value="data.value" format="currency" />
            </template>
            <template #cell(summationName)>
              {{ summationGroups.summationName }}
            </template>
            <template #cell(externalCost)="data">
              <i18n-n :value="data.value" format="currency" />
            </template>
            <template #cell(budget)="data">
              <i18n-n :value="data.value" format="currency" />
            </template>
            <template #cell(internalDifference)="data">
              <i18n-n :value="data.value" format="currency" />
            </template>
            <template #cell(externalDifference)="data">
              <i18n-n :value="data.value" format="currency" />
            </template>
            <template #foot()> <div class="text-primary">.</div> </template>
            <template #foot(subcaseName)>
              <div class="text-left">{{ $t("HeaderCase.Display.Totals") }}</div>
            </template>
            <template #foot(internalCost)>
              <div v-if="caseHeader.file">
                <i18n-n
                  :value="
                    caseHeader.file.summationGroups[index].internalGroupCost
                  "
                  format="currency"
                />
              </div>
            </template>
            <template #foot(externalCost)>
              <div v-if="caseHeader.file">
                <i18n-n
                  :value="
                    caseHeader.file.summationGroups[index].externalGroupCost
                  "
                  format="currency"
                />
              </div>
            </template>
            <template #foot(budget)>
              <div v-if="caseHeader.file">
                <i18n-n
                  :value="caseHeader.file.summationGroups[index].groupBudget"
                  format="currency"
                />
              </div>
            </template>
            <template #foot(internalDifference)>
              <div v-if="caseHeader.file">
                <i18n-n
                  :value="
                    caseHeader.file.summationGroups[index]
                      .internalGroupDifference
                  "
                  format="currency"
                />
              </div>
            </template>
            <template #foot(externalDifference)>
              <div v-if="caseHeader.file">
                <i18n-n
                  :value="
                    caseHeader.file.summationGroups[index]
                      .externalGroupDifference
                  "
                  format="currency"
                />
              </div>
            </template>
          </b-table></div
      ></b-tab>
      <resource-pool-view-tab
        showTotals
        :resources="caseHeader.resources || []"
        :duration="caseHeader.duration"
      />
      <ParametersPanel :editMode="false" />
      <reviewers-email :busy="busy" />
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { InternalFields, ExternalFields } from "./helper";
import ParametersPanel from "@/components/HeaderCase/ParametersPanelView.vue";
import ResourcePoolViewTab from "@/components/cost-calculation/resource-pool-view-tab.vue";
import FieldsViewTab from "../cost-calculation/document/fields-view-tab.vue";
import ReviewersEmail from "@/components/cost-calculation/document/reviewers-email-view.vue";

const data = () => {
  return {
    show: false,
    changed: false,
    tab: "Internal",
    monthDurationCase: 0,
  };
};
export default {
  props: {
    busy: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    mapItems(summationGroups) {
      let items = summationGroups.summations.map((s) => {
        return {
          ...s,
          _cellVariants: {
            internalDifference: s.internalDifference < 0 ? "danger" : "success",
            externalDifference: s.externalDifference < 0 ? "danger" : "success",
          },
        };
      });
      return summationGroups.showSubcase ? items : [];
    },
  },
  computed: {
    InternalFields,
    ExternalFields,
    ...mapGetters("Cases", { caseHeader: "caseHeader" }),
    fields() {
      return this[`${this.tab}Fields`];
    },
    filterByBudget() {
      let fields = [];
      this[`${this.tab}Fields`].forEach((element, index) => {
        if (index < 2) {
          fields.push(element);
        }
      });
      return fields;
    },
    items() {
      if (!this.caseHeader.file) return [];
      return this.caseHeader.file.summations.map((s) => {
        return {
          ...s,
          _cellVariants: {
            internalDifference: s.internalDifference < 0 ? "danger" : "success",
            externalDifference: s.externalDifference < 0 ? "danger" : "success",
          },
        };
      });
    },
    resources() {
      if (!this.caseHeader.resources) return [];
      return this.caseHeader.resources;
    },
  },
  data,
  components: {
    ParametersPanel,
    ResourcePoolViewTab,
    FieldsViewTab,
    ReviewersEmail,
  },
};
</script>

<style></style>
